// custom typefaces
import "@fontsource-variable/montserrat"
import "@fontsource/merriweather"
// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"

export const onServiceWorkerUpdateReady = () => {
    const answer = window.confirm(
      `Es gibt neue Inhalte! ` +
        `Möchtest du zur aktuellesten Version aktualisieren?`
    )
  
    if (answer === true) {
      window.location.reload()
    }
  }
